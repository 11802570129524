var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      !_vm.tasks.length
        ? _c("el-empty", {
            attrs: {
              description: _vm.tip,
              image: "https://source.fungsong.com/3452097265742FYF1wx7cNHae.png"
            }
          })
        : _vm._l(_vm.tasks, function(item, index) {
            return _c("div", { key: index, staticClass: "item" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("img", {
                    staticClass: "user_img",
                    attrs: { src: _vm.getTaskImg(item), alt: "" }
                  })
                ]),
                index != _vm.tasks.length - 1
                  ? _c("div", {
                      staticClass: "line",
                      style: {
                        borderLeft: "2px  solid  " + _vm.getBgColor(item)
                      }
                    })
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "descriptions",
                      staticStyle: {
                        "align-items": "center",
                        "margin-bottom": "10px"
                      }
                    },
                    [
                      _c("div", { staticClass: "title " }, [
                        _vm._v(
                          _vm._s(item.result != 9999 ? item.name : "发起了审批")
                        )
                      ]),
                      item.taskType == "COMMENT_TASK"
                        ? _c("div", { staticClass: "descriptions-label" }, [
                            _vm._v("添加了评论")
                          ])
                        : _vm._e(),
                      item.taskType == "SERVICE_TASK"
                        ? _c("div", { staticClass: "descriptions-label" }, [
                            _vm._v("抄送人")
                          ])
                        : _vm._e(),
                      item.approveType
                        ? _c("div", { staticClass: "descriptions-label" }, [
                            _vm._v(_vm._s(_vm.keytype[item.approveType]))
                          ])
                        : _vm._e(),
                      item.assigneeUsers.length > 1
                        ? _c(
                            "div",
                            {
                              staticClass: "descriptions-img",
                              on: {
                                click: function($event) {
                                  return _vm.showSupplement(
                                    item.id || item.taskDefKey
                                  )
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.getIcon(
                                    _vm.revoFlag[item.id || item.taskDefKey]
                                  ),
                                  alt: ""
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  item.assigneeUsers && _vm.revoFlag[item.id || item.taskDefKey]
                    ? _c(
                        "div",
                        { staticClass: "descriptions" },
                        [
                          item.optionList && item.optionList.length
                            ? [
                                item.assigneeUsers.length == 0
                                  ? _c("optionalUser", {
                                      attrs: {
                                        taskdata: item.optionList[0],
                                        users: _vm.users,
                                        taskDefKey: item.taskDefKey
                                      },
                                      on: { chooseUser: _vm.chooseUser }
                                    })
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              ref: "featureRef" + index,
                              refInFor: true,
                              class:
                                item.taskType === "SERVICE_TASK"
                                  ? "value_list_ccCopy"
                                  : "value_list"
                            },
                            _vm._l(item.assigneeUsers, function(sub, i) {
                              return _c(
                                "div",
                                {
                                  key: i,
                                  staticClass: "scrollBarHidden value",
                                  style: { "margin-top": i > 0 ? "10px" : "" }
                                },
                                [
                                  sub.avatarUrl
                                    ? _c("img", {
                                        style: {
                                          filter:
                                            sub.deptId == null &&
                                            item.taskType === "SERVICE_TASK"
                                              ? "brightness(0.6)"
                                              : "",
                                          "margin-right":
                                            item.taskType == "COMMENT_TASK"
                                              ? "20px"
                                              : "0px"
                                        },
                                        attrs: { src: sub.avatarUrl, alt: "cc" }
                                      })
                                    : _c("img", {
                                        style: {
                                          filter:
                                            sub.deptId == null &&
                                            item.taskType === "SERVICE_TASK"
                                              ? "brightness(0.6)"
                                              : "",
                                          "margin-right":
                                            item.taskType == "COMMENT_TASK"
                                              ? "20px"
                                              : "0px"
                                        },
                                        attrs: {
                                          src: require("@/assets/ioc/Group_41.png"),
                                          alt: "aa"
                                        }
                                      }),
                                  _vm.hideImg(item, sub) &&
                                  item.taskType != "COMMENT_TASK"
                                    ? _c("img", {
                                        staticClass: "hide_img",
                                        attrs: {
                                          src: _vm.getTaskStatusImg(item),
                                          alt: "bb"
                                        }
                                      })
                                    : _vm._e(),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "time",
                                      staticStyle: {
                                        "white-space": "nowrap",
                                        color: "rgb(27, 27, 27)"
                                      },
                                      style: {
                                        "margin-left":
                                          _vm.isHide(item) == "fail" ||
                                          _vm.isHide(item) == "normal"
                                            ? "-14px"
                                            : "8px",
                                        color:
                                          sub.deptId == null
                                            ? "rgba(27, 27, 27, 0.4)"
                                            : "rgba(27, 27, 27, 1)"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.handleNodeNull(sub)) +
                                          " "
                                      )
                                    ]
                                  ),
                                  sub.deptId == null
                                    ? _c("div", { staticClass: "quit" }, [
                                        _vm._v(" 离职 ")
                                      ])
                                    : _vm._e(),
                                  sub.userName.indexOf(
                                    "(节点审批人为空，已转交管理员)"
                                  ) != -1
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              "节点审批人为空，已转交管理员",
                                            placement: "top-start"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-warning"
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  item.taskType != "SERVICE_TASK"
                                    ? _c(
                                        "div",
                                        { staticClass: " supplement" },
                                        [
                                          item.result &&
                                          item.taskType != "COMMENT_TASK"
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "10px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.keytype[item.result]
                                                      )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          item.endTime
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formattedTime(
                                                        item.endTime
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : item.createTime
                                            ? _c("div", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.formattedTime(
                                                        item.createTime
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            0
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  item.result == 1
                    ? _c("div", { staticClass: "descriptions" }, [
                        _c("div", { staticClass: "timeDisplay" }, [
                          _vm._v(_vm._s(_vm.diffTime(item.createTime)))
                        ]),
                        _c("div", { staticClass: "label" }, [_vm._v("已等待")])
                      ])
                    : _vm._e(),
                  item.reason && item.result != 12
                    ? _c(
                        "div",
                        {
                          class:
                            item.taskType == "COMMENT_TASK"
                              ? "reason ongoing"
                              : "reason",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "space-between"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              item.reason.indexOf("主动取消任务") > -1
                                ? _vm.tasks[0].assigneeUsers[0].userName +
                                    "：" +
                                    item.reason
                                : item.reason
                            ) + " "
                          ),
                          item.taskType == "COMMENT_TASK" &&
                          _vm.revocationFlag(item.createTime)
                            ? _c(
                                "el-button",
                                {
                                  class: _vm.fontColor,
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteComment(item)
                                    }
                                  }
                                },
                                [_vm._v("撤销")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  [
                    item.result == 2 &&
                    (item.picture.length > 0 || item.attachment.length > 0)
                      ? _c(
                          "div",
                          { staticClass: "reason", class: _vm.fontColor },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-bottom": "8px" } },
                              [
                                item.picture && item.picture.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "picture_list" },
                                      _vm._l(item.picture, function(sub, p) {
                                        return _c(
                                          "div",
                                          {
                                            key: p,
                                            on: {
                                              contextmenu: function($event) {
                                                $event.preventDefault()
                                                return _vm.showContextMenu(
                                                  sub,
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "50px",
                                                height: "50px"
                                              },
                                              attrs: { src: sub.url, alt: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.preview(sub)
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  : _vm._e(),
                                item.attachment && item.attachment.length > 0
                                  ? _c(
                                      "div",
                                      { staticClass: "attachment_list" },
                                      _vm._l(item.attachment, function(sub, p) {
                                        return _c("div", { key: p }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                                gap: "10px"
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-document",
                                                staticStyle: {
                                                  "font-size": "28px"
                                                }
                                              }),
                                              _c("p", [
                                                _vm._v(_vm._s(sub.name))
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "el-link",
                                                {
                                                  staticClass: "yulan",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.preview(sub)
                                                    }
                                                  }
                                                },
                                                [_vm._v("预览")]
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      }),
                                      0
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    item.result == 12
                      ? _c(
                          "div",
                          { staticClass: "reason", class: _vm.fontColor },
                          [
                            _c("div", [
                              _vm._v(
                                "已于 " +
                                  _vm._s(_vm.formattedTime(item.endTime)) +
                                  " 撤回评论"
                              )
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                ],
                2
              )
            ])
          }),
      _c("FilePreviewDialog", {
        ref: "FileDialog",
        attrs: { fileData: _vm.fileData, width: "90%" },
        on: { closePreviewDialog: _vm.closePreviewDialog }
      }),
      _vm.showMenu
        ? _c(
            "div",
            {
              staticClass: "menu_list",
              style: {
                top: _vm.address.top + "px",
                left: _vm.address.left + "px"
              }
            },
            [
              _c(
                "div",
                { staticClass: "menu_item", on: { click: _vm.copyImg } },
                [_vm._v(" 复制 ")]
              )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }