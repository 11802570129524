import request from '@/utils/request'

// 审批中心

// 我发起的列表
export function getProcessInstanceList(data) {
    return request({
        url: "bpm/process-instance/my-page",
        method: "post",
        data,
    });
}


// 待办列表
export function getTaskList(data) {
    return request({
        url: "bpm/task/todo-page",
        method: "post",
        data,
    });
}

// 已办列表

export function getTaskDoneList(data) {
    return request({
        url: "bpm/task/done-page",
        method: "post",
        data,
    });
}


// 我收到的
export function getProcessReceivedList(data) {
    return request({
        url: "bpm/process-instance/my-received",
        method: "post",
        data,
    });
}

// 转交我的
export function getCommonRecordList(data) {
    return request({
        url: "bpm/common/record",
        method: "post",
        data,
    });
}

// 获取分组
export function getFlowManagementList(params) {
    return request({
        url: "bpm/flowManagement/findGroupAndFlow",
        method: "get",
        params,
    });
}

// 获取人员
export function getArrSimpleList(params) {
    return request({
        url: "system/bpm/user/list-all-simple",
        method: "get",
        params,
    });
}

// 获取收到的条数
export function getCountMsg(processInstanceId = '', isAll = '') {
    const url = `bpm/process-instance/countMsg?processInstanceId=${processInstanceId}&isAll=${isAll}`
    return request({
        url: url,
        method: "get",
    });
}

// 获取人员
export function getUserList(params) {
    return request({
        url: "system/user/list",
        method: "get",
        params,
    });
}