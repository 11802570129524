<template>
  <div class="tab-box">

    <div ref="approvalCenter">
      <!-- 列表类型 -->
      <div class="tab-header">
        <el-radio-group v-model="chosen" @input="OnChangeType">
          <el-radio-button v-for="row in listType" :label="row.type" :key="row.type">
            <span>
              {{ row.name }}
            </span>
            <div class="num" v-if="row.type == 'todo' && total > 0 ">{{ total }}</div>
            <div class="num" v-if="row.type == 'iReceived' && CountMsg > 0">{{ CountMsg }}</div>
          </el-radio-button>
        </el-radio-group>
      </div>

      <!-- 搜索条件 -->
      <div class="search-header">
        <div class="sx-btn-box-left">
          <el-input placeholder="流程名称/摘要" v-model="formInline.name" class="search-input" style="width: 160px;"
            @keyup.enter.native="search">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-select v-if="chosen != 'iInitiated'" filterable remote :remote-method="remoteMethod" v-model="formInline.creator" clearable :loading="loading" placeholder="发起人"
            class="search-input" style="width: 100px;">
            <el-option v-for="row in UserList" :key="row.userId" :label="row.userName" :value="row.userId">
            </el-option>
          </el-select>
          <el-select v-model="formInline.status" v-if="chosen != 'todo'" clearable filterable placeholder="请选择流程状态"
            class="search-input" style="width: 160px;">
            <el-option v-for="row in processStatusList" :key="row.type" :label="row.name" :value="row.type">
            </el-option>
          </el-select>
          <div class="sx-btn-box">
            <div class="sx-btn pointer" @click="resetSearch">
              重置
            </div>
            <div class="sx-btns pointer" @click="search">
              搜索
            </div>
          </div>
        </div>
        <div class="sx-btn-box-right">
          <el-popover placement="left" trigger="click" :append-to-body="false" width="366" :popper-options="{}">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" :label-position="'top'"
              style="width: 100%;">
              <el-form-item label="审批编号" style="width: 100%;">
                <el-input v-model="formInline.processInstanceId" style="width: 340px;" placeholder="请输入审批编号"
                  clearable />
              </el-form-item>
              <el-form-item label="所属分组" style="width: 100%;">
                <el-cascader class="sx-btn-box-search" style="width: 340px;" v-model="formInline.formIds"
                  :options="categoryList" :show-all-levels="false"
                  :props="{ label: 'name', value: 'id', multiple: true, emitPath: false,  }" :collapse-tags="true">
                </el-cascader>
              </el-form-item>
              <el-form-item :label="getTimeText()" style="width: 100%;">
                <el-date-picker align="center" style="width: 100%;height: 36px;" v-model="formInline.createTime"
                  value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="-" start-placeholder="开始日期"
                  end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" :append-to-body="true" size="mini" />
              </el-form-item>
              <el-form-item label="结束时间" style="width: 100%;">
                <el-date-picker align="center" style="width: 100%;height: 36px;" v-model="formInline.endTime"
                  value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="-" start-placeholder="开始日期"
                  end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" :append-to-body="true" size="mini" />
              </el-form-item>
            </el-form>
            <div class="sx-btn-box">
              <div class="sx-btn pointer" @click="resetSearch">
                重置
              </div>
              <div class="sx-btns pointer" @click="search">
                搜索
              </div>
            </div>
            <div class="about-search-input-item pointer" slot="reference">
              <i class="el-icon-search"></i>
              <span>高级筛选</span>
            </div>
          </el-popover>
        </div>
      </div>

      <!-- 未读已读 -->
      <div v-show="chosen == 'iReceived'" class="unread">
        <el-checkbox v-model="Read">仅未读</el-checkbox>
        <span @click="ReadAll"> 全部标记为已读 </span>
      </div>

      <!-- 列表内容 -->
      <div class="tab-content" v-show="listType[chosenIndex].data.length != 0">
        <!-- 待办列表 -->
        <div v-show="chosen == 'iInitiated'">
          <el-table :data="listType[0].data" style="width: 100%" height="calc(100vh - 270px)" ref="iInitiated"
            @row-click="onRrowClick" @sort-change="onSortChange" row-class-name="success-row" highlight-current-row
            :header-cell-style="{background:'rgb(86, 105, 236)',color:'rgb(250, 251, 252)'}">
            <el-table-column label="流程名称" align="center">
              <template slot-scope="scope">
                <div class="about-table-name" v-if="scope.row.initiator">
                  <div>
                    <img :src="scope.row.initiator.avatarUrl || require(`@/assets/ioc/Group_41.png`)" alt="">
                    <span style="color: rgb(86, 105, 236)" @click.stop="UserClick(scope.row.initiator.userId)">{{
                      scope.row.initiator.userName
                      }}<span style="color: rgb(27, 27, 27);margin-left: 2px;">提交的</span></span>
                  </div>
                </div>
                <div>
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="摘要" align="center">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top">
                  <div v-for="(item,index) in JSON.parse(scope.row.digest ||'[]')" :key="index">
                    <div v-if="index <= 3">
                      <span>{{item.name}}:</span>
                      <span>{{item.value == "undefined" ? '' : item.value}}</span>
                    </div>
                  </div>
                  <div slot="reference">
                    <div v-for="(item,index) in JSON.parse(scope.row.digest ||'[]')" :key="index">
                      <div v-if="index <= 3" class="name-wrapper">
                        <span>{{item.name}}:</span>
                        <span>{{item.value == "undefined" ? '' : item.value}}</span>
                      </div>
                    </div>

                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="当前审批人" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.assigner">
                  <span style="color: rgb(86, 105, 236)" v-for="(item,index) in scope.row.assigner" :key="index"
                    @click.stop="UserClick(item.userId)">
                    {{ item.userName }}{{ index < scope.row.assigner.length - 1 ? '/' : '' }} </span>
                </div>
                <div v-else> - </div>
              </template>
            </el-table-column>
            <el-table-column label="所属分组" align="center">
              <template slot-scope="scope">
                <span> {{ scope.row.groupName || "暂无" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="提交时间" sortable="custom" align="center">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="结束时间" align="center">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.endTime) || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="流程状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1" style="color: #5669ec;">进行中</span>
                <span v-if="scope.row.status == 2 && scope.row.result == 2" style="color: #67c23a;">已通过</span>
                <span v-if="scope.row.status == 2 && scope.row.result == 3" style="color: #f83e45;">不通过</span>
                <span v-if="scope.row.status == 3" style="color: #848484;">已撤回</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 已发起列表 -->
        <div v-show="chosen == 'todo'">
          <el-table :data="listType[1].data" style="width: 100%" height="calc(100vh - 270px)" ref="todo"
            @row-click="onRrowClick" @sort-change="onSortChange" row-class-name="success-row" highlight-current-row
            :header-cell-style="{background:'rgb(86, 105, 236)',color:'rgb(250, 251, 252)'}">
            <el-table-column label="流程名称" align="center">
              <template slot-scope="scope">
                <div class="about-table-name" v-if="scope.row.processInstance">
                  <div>
                    <img :src="scope.row.processInstance.avatarUrl || require(`@/assets/ioc/Group_41.png`)" alt="">
                    <span style="color: rgb(86, 105, 236)"
                      @click.stop="UserClick(scope.row.processInstance.startUserId)">{{
                      scope.row.processInstance.startUserName
                      }}<span style="color: rgb(27, 27, 27);margin-left: 2px;">提交的</span></span>
                  </div>
                </div>
                <div v-if="scope.row.processInstance">
                  {{ scope.row.processInstance.name }}
                </div>
                <div v-if="!scope.row.processInstance">
                  -
                </div>
              </template>
            </el-table-column>
            <el-table-column label="摘要" align="center">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top">
                  <div v-for="(item,index) in JSON.parse(scope.row.digest ||'[]')" :key="index">
                    <div v-if="index <= 3">
                      <span>{{item.name}}:</span>
                      <span>{{item.value == "undefined" ? '' : item.value}}</span>
                    </div>
                  </div>
                  <div slot="reference">
                    <div v-for="(item,index) in JSON.parse(scope.row.digest ||'[]')" :key="index">
                      <div v-if="index <= 3" class="name-wrapper">
                        <span>{{item.name}}:</span>
                        <span>{{item.value == "undefined" ? '' : item.value}}</span>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="发起人" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.processInstance && scope.row.processInstance.startUserName"
                  style="color: #5669ec;">
                  <span @click.stop="UserClick(scope.row.processInstance.startUserId)"> {{
                    scope.row.processInstance.startUserName }}</span>
                </div>
                <div v-else> - </div>
              </template>
            </el-table-column>
            <el-table-column label="所属分组" align="center">
              <template slot-scope="scope">
                <span> {{ scope.row.groupName || "暂无" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="提交时间" sortable="custom" align="center">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) || "-" }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 已收到列表 -->
        <div v-show="chosen == 'finished'">
          <el-table :data="listType[2].data" style="width: 100%" height="calc(100vh - 270px)" ref="finished"
            @row-click="onRrowClick" @sort-change="onSortChange" row-class-name="success-row" highlight-current-row
            :header-cell-style="{background:'rgb(86, 105, 236)',color:'rgb(250, 251, 252)'}">
            <el-table-column label="流程名称" align="center">
              <template slot-scope="scope">
                <div class="about-table-name" v-if="scope.row.processInstance">
                  <div>
                    <img :src="scope.row.processInstance.avatarUrl || require(`@/assets/ioc/Group_41.png`)" alt="">
                    <span style="color: rgb(86, 105, 236)"
                      @click.stop="UserClick(scope.row.processInstance.startUserId)">{{
                      scope.row.processInstance.startUserName
                      }}<span style="color: rgb(27, 27, 27);margin-left: 2px;">提交的</span></span>
                  </div>
                </div>
                <div v-if="scope.row.processInstance">
                  {{ scope.row.processInstance.name }}
                </div>
                <div v-if="!scope.row.processInstance">
                  -
                </div>
              </template>
            </el-table-column>
            <el-table-column label="摘要" align="center">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top">
                  <div v-for="(item,index) in JSON.parse(scope.row.digest ||'[]')" :key="index">
                    <div v-if="index <= 3">
                      <span>{{item.name}}:</span>
                      <span>{{item.value == "undefined" ? '' : item.value}}</span>
                    </div>
                  </div>
                  <div slot="reference">
                    <div v-for="(item,index) in JSON.parse(scope.row.digest ||'[]')" :key="index">
                      <div v-if="index <= 3" class="name-wrapper">
                        <span>{{item.name}}:</span>
                        <span>{{item.value == "undefined" ? '' : item.value}}</span>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="发起人" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.processInstance && scope.row.processInstance.startUserName"
                  style="color: #5669ec;">
                  <span @click.stop="UserClick(scope.row.processInstance.startUserId)"> {{
                    scope.row.processInstance.startUserName }}</span>
                </div>
                <div v-else> - </div>
              </template>
            </el-table-column>
            <el-table-column label="所属分组" align="center">
              <template slot-scope="scope">
                <span> {{ scope.row.groupName || "暂无" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="提交时间" align="center">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="结束时间" sortable="custom" align="center">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.endTime) || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="流程状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1" style="color: #5669ec;">进行中</span>
                <span v-if="scope.row.status == 2 && scope.row.result == 2" style="color: #67c23a;">已通过</span>
                <span v-if="scope.row.status == 2 && scope.row.result == 3" style="color: #f83e45;">不通过</span>
                <span v-if="scope.row.status == 3" style="color: #848484;">已撤回</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 我收到的列表 -->
        <div v-show="chosen == 'iReceived'">
          <el-table :data="listType[3].data" style="width: 100%" height="calc(100vh - 295px)" ref="iReceived"
            @row-click="onRrowClick" @sort-change="onSortChange" row-class-name="success-row" highlight-current-row
            :header-cell-style="{background:'rgb(86, 105, 236)',color:'rgb(250, 251, 252)'}">
            <el-table-column label="流程名称" align="center">
              <template slot-scope="scope">
                <div class="about-table-name" v-if="scope.row.processInstance">
                  <div>
                    <div class="unread" v-if="scope.row.isRead == 0"></div>
                    <img :src="scope.row.processInstance.avatarUrl || require(`@/assets/ioc/Group_41.png`)" alt="">
                    <span style="color: rgb(86, 105, 236)"
                      @click.stop="UserClick(scope.row.processInstance.startUserId)">{{
                      scope.row.processInstance.startUserName
                      }}<span style="color: rgb(27, 27, 27);margin-left: 2px;">提交的</span></span>
                  </div>
                </div>
                <div v-if="scope.row.processInstance">
                  {{ scope.row.processInstance.name }}
                </div>
                <div v-if="!scope.row.processInstance">
                  -
                </div>
              </template>
            </el-table-column>
            <el-table-column label="摘要" align="center">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top">
                  <div v-for="(item,index) in JSON.parse(scope.row.digest ||'[]')" :key="index">
                    <div v-if="index <= 3">
                      <span>{{item.name}}:</span>
                      <span>{{item.value == "undefined" ? '' : item.value}}</span>
                    </div>
                  </div>
                  <div slot="reference">
                    <div v-for="(item,index) in JSON.parse(scope.row.digest ||'[]')" :key="index">
                      <div v-if="index <= 3" class="name-wrapper">
                        <span>{{item.name}}:</span>
                        <span>{{item.value == "undefined" ? '' : item.value}}</span>
                      </div>
                    </div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column label="发起人" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.processInstance && scope.row.processInstance.startUserName"
                  style="color: #5669ec;">
                  <span @click.stop="UserClick(scope.row.processInstance.startUserId)"> {{
                    scope.row.processInstance.startUserName }}</span>
                </div>
                <div v-else> - </div>
              </template>
            </el-table-column>
            <el-table-column label="所属分组" align="center">
              <template slot-scope="scope">
                <span> {{ scope.row.groupName || "暂无" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="收到时间" sortable="custom" align="center">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.createTime) || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="结束时间" align="center">
              <template slot-scope="scope">
                <span>{{ parseTime(scope.row.endTime) || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="流程状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.result == 1" style="color: #5669ec;">进行中</span>
                <span v-if="scope.row.result == 2" style="color: #67c23a;">已通过</span>
                <span v-if="scope.row.result == 3" style="color: #f83e45;">不通过</span>
                <span v-if="scope.row.result == 4" style="color: #848484;">已撤回</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <!-- 空数据 -->
      <div v-show="listType[chosenIndex].data.length == 0" class="empty-data">
        <img class="about-img" src="https://source.fungsong.com/3453288888914YKG1W1O2ZTgm.png" alt="">
        <div class="about-text">暂无数据</div>
      </div>
    </div>
    <!-- 详情抽屉 -->
    <processDetails ref="processDetails" :drawerData="drawerData" @closeDrawer="closeDrawer" @handleClose="handleClose">
    </processDetails>
  </div>
</template>

<script>
  import { getProcessInstanceList, getCountMsg, getTaskList, getTaskDoneList, getProcessReceivedList, getFlowManagementList, getArrSimpleList,getUserList } from '@/api/examines.js'
  import processDetails from "@/components/processDetails.vue";
  import { parseTime } from "@/utils/time";

  export default {
    name: "approvalList",
    components: {
      processDetails
    },
    data() {
      return {
        // 列表类型
        listType: [
          {
            name: "我发起的",
            type: 'iInitiated',
            data: [],
            getData: 'getIInitiated',
            field: 'm.create_time'
          },
          {
            name: "待办任务",
            type: 'todo',
            data: [],
            getData: 'getTodo',
            field: 'rt.create_time_'
          },
          {
            name: "已办任务",
            type: 'finished',
            data: [],
            getData: 'getFinished',
            field: 'm.end_time'
          },
          {
            name: "我收到的",
            type: 'iReceived',
            data: [],
            getData: 'getIReceived',
            field: 'm.create_time'
          },
        ],
        // 流程状态  
        processStatusList: [
          {
            name: "进行中",
            type: '1'
          },
          {
            name: "已完成",
            type: '2'
          },
          {
            name: "已取消",
            type: '3'
          },
        ],
        // 详情弹窗数据
        drawerData: {},
        // 当前选中列表类型
        chosen: 'iInitiated',
        // 代办条数
        total: 0,
        // 收到的消息条数
        CountMsg: 0,
        // 搜索条件
        formInline: {
          // 流程名称/摘要
          name: '',
          // 发起人
          creator: '',
          // 流程状态
          status: '',
          // 所属分组
          formIds: [],
          // 创建时间
          createTime: [],
          // 结束时间
          endTime: [],
          // 页数
          pageNo: 1,
          // 条数
          pageSize: 15,
          // 已读/未读 0 , 1
          isRead: '',
          // 排序字段
          sortingFields: [
            {
              field: "m.end_time",
              order: "desc"
            }
          ],
          // 审批编号
          processInstanceId: '',
        },
        // 未读消息
        Read: false,
        // 时间转换方法
        parseTime,
        // 人员列表
        UserList: [],
        // 分组列表
        categoryList: [],
        // scroll事件
        scrollEvent: '',
        loading: false,
      };
    },
    computed: {
      chosenIndex: function () {
        return this.listType.findIndex(item => item.type === this.chosen);
      }
    },
    // 初始化数据，绑定列表滚动事件
    async mounted() {
      const { needDealt } = this.$route.query; // 获取名为param1的查询参数
      if (needDealt == 2) {
        this.chosen = 'todo';
      }
      this.getFlowManagement();
      this.setAgencyUnmeter();
      this.setReceivedUnmeter();
      this.listType[0].data = await this.getIInitiated();
      // await this.getUserList();
      this.listType.forEach((element, index) => {
        this.handleScroll(element, index);
        if (index > 0) {
          this[element.getData]().then(res => {
            this.listType[index].data = res;
          });
        }
      });
    },
    // 监听改变需要立刻搜索的条件
    watch: {
      Read: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.formInline.isRead = newVal ? 0 : '';
        }
      },
    },
    methods: {
      // 获取我发起的列表数据
      async getIInitiated() {
        const clonedForm = structuredClone(this.formInline);
        clonedForm.sortingFields[0].field = "m.create_time";
        const { data } = await getProcessInstanceList(clonedForm);
        return data.list;
      },
      // 获取已办任务列表数据
      async getFinished() {
        const clonedForm = structuredClone(this.formInline);
        clonedForm.sortingFields[0].field = "m.end_time";
        const { data } = await getTaskDoneList(clonedForm);
        return data.list;
      },
      // 获取代办任务列表数据
      async getTodo() {
        const clonedForm = structuredClone(this.formInline);
        clonedForm.sortingFields[0].field = "rt.create_time_";
        const { data } = await getTaskList(clonedForm);
        return data.list;
      },
      // 获取我收到的列表数据
      async getIReceived() {
        const clonedForm = structuredClone(this.formInline);
        clonedForm.sortingFields[0].field = "m.create_time";
        const { data } = await getProcessReceivedList(clonedForm);
        return data.list;
      },
      // 获取人员列表数据
      async remoteMethod(query) {
        if (query !== '') {
          const qey  =  {
            pageNum: 1,
            pageSize: 10,
            userName: query
          }
          this.loading = true;
          const { rows } = await getUserList(qey);
          this.loading = false;
          this.UserList = rows;
        } else {
          this.UserList  = [];
        }
      },
      // 获取分组信息
      async getFlowManagement() {
        getFlowManagementList(true).then(res => {
          this.categoryList = res.data;
        });
      },
      // 设置代办任务条数
      async setAgencyUnmeter() {
        const { data } = await getTaskList({});
        this.total = data.total;
      },
      // 设置我收到的条数
      async setReceivedUnmeter(id) {
        if (id) {
          await getCountMsg(id);
        } else {
          const { data } = await getCountMsg();
          this.CountMsg = data;
        }
      },
      // 切换列表类型
      OnChangeType() {
        if (this.chosen == 'iInitiated') {
          this.$refs.iInitiated.doLayout()
        }
        const isSearch = this.formInline.name || this.formInline.creator || this.formInline.status || this.formInline.isRead === 0
        this.reset();
        if (!isSearch) {
          this.search();
        }
      },
      // 搜索数据
      search() {
        const index = this.chosenIndex;
        const usedPageNo = this.formInline.pageNo;
        this.$refs[this.chosen].$el.querySelector('.el-table__body-wrapper').scrollTop = 0;
        this.formInline.pageNo = 1;
        this[this.listType[index].getData]().then(res => {
          this.listType[index].data = res;
        });
      },
      //详情抽屉改变回调
      closeDrawer() {
        setTimeout(() => {
          this.setAgencyUnmeter();
          this.setReceivedUnmeter();
          this.search();
          this.handleClose();
        }, 1000);

      },
      // 点击用户卡片
      UserClick(id) {
        this.openUserCard(id)
      },
      // 关闭侧边栏
      closeTheSidebar(event) {
        event.stopPropagation();
        this.$refs.processDetails.drawer = false;
        const parent = this.$refs.approvalCenter
        parent.removeEventListener('click', this.closeTheSidebar);
      },
      // 行点击事件
      async onRrowClick(row) {
        if (this.$refs.processDetails.drawer) return
        const data = JSON.parse(JSON.stringify(row));
        if (this.chosen !== 'iInitiated') {
          data.taskId = row.taskId ? row.taskId : row.id;
          data.id = row.processInstance.id;
        }
        this.drawerData = data;
        if (this.chosen === 'iReceived') {
          await this.setReceivedUnmeter(data?.id);
          await this.setReceivedUnmeter();
          delete this.drawerData.taskId;
          this.listType[this.chosenIndex].data = this.listType[this.chosenIndex].data.map(err => {
            if (err.id == row.id) {
              err.isRead = 1;
            };
            return err;
          })
        }
        this.$refs.processDetails.drawer = true;
        const parent = this.$refs.approvalCenter
        setTimeout(() => {
          parent.addEventListener('click', this.closeTheSidebar);
        }, 500)
      },

      // 点击排序事件
      onSortChange(prop) {
        const dictionaries = {
          'ascending': 'asc',
          'descending': 'desc'
        }
        const { order } = prop;
        this.formInline.sortingFields[0].order = dictionaries[order] || 'desc';
        this.search();
      },

      // 绑定滚动事件
      handleScroll(element, index) {
        let isBottom = false;
        let $then = this;
        let el = this.$refs[element.type].$el
        const selectWrap = el.querySelector('.el-table__body-wrapper');
        $then.scrollEvent = function () {
          if (this.scrollTop + this.clientHeight >= this.scrollHeight - 10) {
            if (!isBottom) {
              isBottom = true; // 设置状态为已到底部
              $then.formInline.pageNo++;
              $then[element.getData]().then(res => {
                console.log('到达底部,触发加载更多')
                $then.listType[index].data.push(...res)
                isBottom = false; // 如果滚动离开底部区域，重置状态
              })
            }
          }
        }
        selectWrap.addEventListener('scroll', $then.scrollEvent)
      },
      // 全部标记为已读
      ReadAll() {
        getCountMsg('', 1).then(res => {
          this.search();
          this.setReceivedUnmeter();
        })
      },
      // 重置搜索条件
      reset() {
        this.formInline = {
          // 流程名称/摘要
          name: '',
          // 发起人
          creator: '',
          // 流程状态
          status: '',
          // 所属分组
          formIds: [],
          // 创建时间
          createTime: [],
          // 结束时间
          endTime: [],
          // 页数
          pageNo: 1,
          // 条数
          pageSize: 15,
          // 已读/未读 0 , 1
          isRead: '',
          // 排序字段
          sortingFields: [
            {
              field: this.listType[this.chosenIndex].field,
              order: "desc"
            }
          ],
          // 审批编号
          processInstanceId: '',
        };
        this.Read = false;
      },
      // 重置按钮触发事件
      resetSearch() {
        this.reset();
        this.search();
      },
      // 返回时间文本
      getTimeText() {
        switch (this.chosen) {
          case 'iInitiated':
            return '提交时间';
            break;
          case 'todo':
            return '发起时间';
            break;
          default:
            return '创建时间';
            break;
        }
      },
      // 抽屉关闭事件
      handleClose() {
        const parent = this.$refs.approvalCenter
        parent.removeEventListener('click', this.closeTheSidebar);
      }
    },
    // 退出页面，清除绑定事件
    beforeDestroy() {
      this.listType.forEach(element => {
        const selectWrap = this.$refs[element.type].$el.querySelector('.el-table__body-wrapper');
        selectWrap.removeEventListener('scroll', this.scrollEvent);
      });
    }
  };
</script>

<style scoped lang="scss">
  .tab-box {
    width: 100%;
    padding: 12px 16px;
    box-sizing: border-box;
    position: relative;

    height: 100vh;

    .tab-header {
      text-align: left;

      .num {
        width: 15px;
        height: 15px;
        background-color: rgb(255, 82, 25);
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: -32px;
        right: -54px;
        font-size: 10px;
        color: #ffffff;
      }

      ::v-deep .el-radio-button__inner {
        line-height: 23px;
        text-align: center;
      }
    }

    .search-header {
      display: flex;
      margin-top: 14px;
      justify-content: space-between;

      .sx-btn-box-left {
        display: flex;
        gap: 8px;


        .sx-btn-box {
          display: flex;
          justify-content: space-between;
          gap: 8px;

          .sx-btn {
            width: 68px;
            height: 34px;
            border-radius: 2px;
            background: rgba(243, 244, 246, 1);
            line-height: 36px;
            text-align: center;
            color: var(--, rgba(132, 132, 132, 1));
            font-size: 13px;
          }

          .sx-btns {
            width: 68px;
            height: 34px;
            border-radius: 2px;
            background: rgba(86, 105, 236, 1);
            color: rgba(255, 255, 255, 1);
            font-size: 13px;
            line-height: 36px;
            text-align: center;
          }

        }
      }

      .sx-btn-box-right {
        display: flex;
        gap: 8px;

        .about-search-input-item {
          width: 96px;
          height: 34px;
          border-radius: 6px;
          display: flex;
          align-items: center;
          background: rgb(243, 244, 246);
          justify-content: space-evenly;
          margin-right: 8px;

          span {
            color: rgba(27, 27, 27, 1);
            font-family: "Noto Sans SC";
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
          }

          i {
            font-size: 14px;
          }
        }

        .sx-btn-box {
          display: flex;
          justify-content: space-between;
          margin-top: 16px;

          .sx-btn {
            width: 112px;
            height: 44px;
            border-radius: 2px;
            background: rgba(243, 244, 246, 1);
            line-height: 44px;
            text-align: center;
            color: var(--, rgba(132, 132, 132, 1));
            font-size: 13px;
          }

          .sx-btns {
            width: 204px;
            height: 44px;
            border-radius: 2px;
            background: rgba(86, 105, 236, 1);
            color: rgba(255, 255, 255, 1);
            font-size: 13px;
            line-height: 44px;
            text-align: center;
          }

        }

        .sx-btn-box-search {

          ::v-deep .el-input__inner {
            height: 36px !important;
          }
        }

        ::v-deep .el-form-item {
          margin-bottom: 0px;
          margin-right: 0px !important;
        }

        ::v-deep .el-input__inner {
          height: 36px !important;
        }
      }
    }

    .unread {
      text-align: left;
      margin-top: 10px;
      height: 10px;

      span {
        font-size: 14px;
        color: rgb(90, 104, 228);
        cursor: pointer;
        margin-left: 5px;
      }
    }

    .empty-data {

      .about-text {
        text-align: center;
        color: rgba(132, 132, 132, 1);
        font-family: "Noto Sans SC";
        font-weight: 400;
        font-size: 14px;
      }
    }

    .tab-content {
      margin-top: 20px;

      ::v-deep .el-table__header-wrapper {
        border-radius: 6px 6px 0 0;
      }

      ::v-deep .gutter {
        background: rgb(86, 105, 236)
      }

      .about-table-name {
        >div {
          display: flex;
          justify-content: center;

          .unread {
            width: 8px;
            height: 8px;
            background-color: #ff0000;
            border-radius: 10px;
            margin-right: 5px;
            margin-top: 5px;
          }
        }

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }

      .name-wrapper {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  ::v-deep .success-row {
    background-color: rgb(243, 244, 246) !important;
  }

  ::v-deep .el-table__body-wrapper {
    background-color: rgb(243, 244, 246);
  }

  ::v-deep .el-table::before {
    background-color: transparent;
  }

  ::v-deep .el-drawer__wrapper {
    pointer-events: none !important;
  }

  ::v-deep .el-drawer {
    pointer-events: all !important;
  }

  ::v-deep .el-table__body tr.current-row > td.el-table__cell{
    background-color: rgb(199 225 255);
  }

  .pointer {
    cursor: pointer !important;
  }
</style>